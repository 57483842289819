import { isEmpty } from "lodash";
import React from "react";
import  secureLocalStorage  from  "react-secure-storage";
import Cookies from "js-cookie";


const Home = (props) => {
    const render = () => {
        let role = secureLocalStorage.getItem('role');
        if (!isEmpty(role) && !isEmpty(Cookies.get("jwt"))) {
            if (role === 'admin') {
                return props.history.push(`/${role}`);
            } else {
                return props.history.push(`/${role}/home`);
            }
        } else {
            return props.history.push(`/auth/login`);
        }
    }

    return (
        <>
            {render()}
        </>
    );
};

export default Home;