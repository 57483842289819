import { Store } from "react-notifications-component";

export const sendNotification = (type, title, message) => {
  try {
    Store.addNotification({
      title,
      message,
      type,
      insert: "top",
      container: "bottom-left",
      content: () => (
        <div className={`notificationCard ${type}`}>
          <p className="notificationTitle">{title}</p>
          <h5 className="notificationMessg">{message}</h5>
        </div>
      ),
      animationOut: ["animated", "fadeOutRight"],
      dismiss: {
        duration: 3000,
      },
      width: 250
    });
  } catch (e) {
    console.log(e.message);
  }
};