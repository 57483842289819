import React, { useContext, useEffect } from "react";
import { OrderContext } from "../../context/orders-context";
import { Sliders } from "react-feather";
import { evaluate } from "mathjs";
import { isEmpty } from "lodash";

const SummSelected = (props) => {

  const [totals, setTotals] = React.useState();
  const OrderCunsumer = useContext(OrderContext);

  useEffect(() => {
      let total = 0;
      let total_usdt = 0;
      for (const order of OrderCunsumer.orderArray) {
        total += parseFloat(order.amount)
        total_usdt += evaluate(`(${order.amount} / ${order.metadata.obmenka_rate}) - ${order.amount} / ${order.course}`);
      }
      setTotals({ total, total_usdt });
  }, [OrderCunsumer.orderArray]);
  
  return (
    <>
      { OrderCunsumer.orderArray.length > 0 && !isEmpty(totals) && (
        <div className="card mb-4">
          <div className="card-body">
            <div className="row">
              <h5 className="mb-4 color-primary" style={{textTransform: 'none'}} >Суммы по выделеным заявкам:
                <Sliders className="link-icon ms-2 mb-1" style={{width: '16px'}}/>          
              </h5>
              <h5 className="my-2">Оборот: {totals.total} RUB</h5>
              <h5 className="my-2">Доход: {parseFloat(totals.total_usdt).toFixed(2)} usdt</h5>
            </div>
            <div className="row">
              <div className="col-6">
                <button
                  className="btn btn-line-primary btn-line-primary-hover btn-small mt-3"
                  onClick={() => props.handleClearSelected(true) }
                >
                  Очистить выделенные заявки
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SummSelected;