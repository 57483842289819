import React, { useContext } from "react";
import { OrderContext } from "../../context/orders-context";
import { sendNotification } from "../../helpers/notification";

import { Filter, X } from "react-feather";
import { isEmpty } from "lodash";

const FilterAdmin = (props) => {

  const [loading, setLoading] = React.useState(false);
  const [filter, setFilter] = React.useState({
    bank: '',
    status: '',
    min_sum: null,
    max_sum: null,
    limit: null,
    manager_id: null,
  });
  const OrderCunsumer = useContext(OrderContext);

  const updateFilter = async (event) => {
    await setFilter({...filter, [event.target.name]: event.target.value});
  };
 
  const submit = async () => {
    setLoading(true);
    props.updateFilter(filter);
    return OrderCunsumer.getOrders(filter)
      .then(() => sendNotification("success", "Успех", "Заявки получены."))
      .then(() => setLoading(false))
      .catch((e) => {
        console.log("e", JSON.stringify(e));
        if (e.response && e.response.body.message) {
          sendNotification("danger", "Ошибка ⚠️", e.response.body.message);
        }
      });
  };

  const clean = async () => {
    setLoading(true);
    await setFilter({ bank: '', status: '', min_sum: null, max_sum: null, limit: null, manager_id: null,});
    return OrderCunsumer.getOrders({ bank: '', status: '', min_sum: null, max_sum: null, limit: null, manager_id: null})
      .then(() => sendNotification("success", "Успех", "Заявки получены."))
      .then(() => setLoading(false))
      .catch((e) => {
        console.log("e", JSON.stringify(e));
        if (e.response && e.response.body.message) {
          sendNotification("danger", "Ошибка ⚠️", e.response.body.message);
        }
      });
  };

  return (
    <>
      <form onSubmit={(e) => e.preventDefault()}>
        <h5 className="mb-3 color-primary">Фильтр
          <Filter className="link-icon ms-2" style={{width: '16px'}}/> 
        </h5>
        <hr />
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label style={{ fontSize: 14, color: '#a4b4c3' }} htmlFor="bank">
                <strong>Банк:</strong>
              </label>
              <select
                onChange={updateFilter}
                className="form-control"
                name="bank"
                id="bank"
              >
                <option value="" defaultValue>
                  Все банки
                </option>
                <option value={"sberbank"}>sberbank</option>
                <option value={"tinkoff"}>tinkoff</option>
                <option value={"visa/master"}>visa/master</option>
              </select>
            </div>
            <div className="form-group">
              <label style={{ fontSize: 14, color: '#a4b4c3' }} htmlFor="status">
                <strong>Статус:</strong>
              </label>
              <select
                onChange={updateFilter}
                className="form-control"
                name="status"
                id="status"
              >
                <option value="" defaultValue>
                  Все статусы
                </option>
                <option value={"CREATED"}>Новая</option>
                <option value={"PENDING"}>В работе</option>
                <option value={"COMPLETED"}>Выполненная</option>
                <option value={"MARKED_AS_COMPLETED"}>Отмечена трейдером как выполненая</option>
                <option value={"ERROR"}>Ошибочная</option>
              </select>
            </div>
            <div className="form-group">
              <label style={{ fontSize: 14, color: '#a4b4c3' }} htmlFor="limit">
                <strong>Сколько показать:</strong>
              </label>
              <input
                type="number"
                onChange={updateFilter}
                className="form-control"
                name="limit"
                id="limit"
                placeholder={1000}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label style={{ fontSize: 14, color: '#a4b4c3' }} htmlFor="min_sum">
                <strong>Сумма от: (в RUB)</strong>
              </label>
              <input
                type="number"
                onChange={updateFilter}
                className="form-control"
                name="min_sum"
                id="min_sum"
              />
            </div>
            <div className="form-group">
              <label style={{ fontSize: 14, color: '#a4b4c3' }} htmlFor="max_sum">
                <strong>Сумма до: (в RUB)</strong>
              </label>
              <input
                type="number"
                onChange={updateFilter}
                className="form-control"
                name="max_sum"
                id="max_sum"
              />
            </div>
            { !isEmpty(props.manager_list) && (
              <div className="form-group">
                <label style={{ fontSize: 14, color: '#a4b4c3' }} htmlFor="manager_id">
                  <strong>Менеджер:</strong>
                </label>
                <select
                  onChange={updateFilter}
                  className="form-control"
                  name="manager_id"
                  id="manager_id"
                >
                  <option value="" defaultValue>
                    Все менеджеры
                  </option>
                  { props.manager_list.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>{item.online ? '🟢' : '⚪️'} id: {item.id} - {item.fullname} ({item.username})</option>
                    )
                  })}
                </select>
              </div> 
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <hr />
            {loading === true ? (
              <button className="btn btn-line-info btn-small">
                <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                Загрузка...
              </button>              
            ) : (
              <>
                <button
                  onClick={(e) => submit(e)}
                  className={`btn btn-line-primary btn-line-primary-hover btn-small mt-1 me-4`}
                >
                  Фильтровать <Filter className="link-icon ml-1" style={{position: 'relative', top: '-1px'}} size={16} />
                </button>
                <button
                  onClick={(e) => clean(e)}
                  className={`btn btn-line-danger btn-line-danger-hover btn-small mt-1 media-mt-3`}
                >
                  Сбросить <X className="link-icon ml-1" style={{position: 'relative', top: '-1px'}} size={16} />
                </button>
              </>
            )}
          </div>
        </div>
      </form>
    </>
  );
};

export default FilterAdmin;