import React from "react";
// import { Manager, System } from "../helpers/api";
// import { isEmpty, sortBy } from "lodash";
// import  secureLocalStorage  from  "react-secure-storage";

// import { sendNotification } from "../helpers/notification";
// import { evaluate } from "mathjs";
// import moment from "moment";

export const ManagerContext = React.createContext({});

export class ManagerProvider extends React.Component {
  state = {

    // getProfit: async () => {
    //   await Manager.getProfit()
    //     .then((res) => {
    //       this.setState({ stat: res.body.data });
    //     })
    //     .catch((error) => {
    //       sendNotification("warning", "Ошибка ⚠️", `Что то пошло не так, не удалось загрузить баланс пользователя`);
    //     });
    // },
    
    // updateState: async (key, val) => {
    //   await this.setState((state) => {
    //     state[key] = val;
    //     return state;
    //   })
    // },

    // assignOrders: async () => {
    //   let range = '1000-500000';
    //   await Manager.assignOrders({ range,  assignedFrom: 'cms' })
    //   .then((res) => {
    //     this.setState({ orders: [...this.state.orders, ...res.body.orders] });
    //     sendNotification("success", "Заявки получено", "Обновляю список заявок");
    //     this.setState({
    //       redirectPath: `/manager/orders`,
    //       redirect: true,
    //     });
    //   })
    //   .catch((error) => {
    //     // console.log("🚀 ~ file: manager-context.js:47 ~ ManagerProvider ~ assignOrders: ~ error:", error)
    //     sendNotification("warning", "Ошибка ⚠️", error.response?.body?.message);
    //   });
    // },

    // getActiveOrders: async () => {
    //   await Manager.getActiveOrders().then((res) => {
    //     this.setState({ orders: res.body.orders });
    //   })
    //   .catch((error) => {
    //     sendNotification("warning", "Ошибка ⚠️", `Не удалось загрузить заявки. Обратитесь в службу поддержки.`);
    //   });
    // },

    // getExportList: async () => {
    //   let res = await Manager.getActiveOrders().then((res) => {
    //     return res.body.orders;
    //   })
    //   .catch((error) => {
    //     sendNotification("warning", "Ошибка ⚠️", `Не удалось загрузить заявки. Обратитесь в службу поддержки.`);
    //   });

    //   let preSorted = sortBy(res, ['bank']);

    //   let formattedStrings = preSorted.map( order => {
    //     if (this.state.debt_list.includes(order.card)) {
    //       return `⛔️ Карта в черном списке: ${order.card} ⛔️ | Банк: ${order.bank} | Сумма: ${order.amount} ${order?.metadata?.telegram ? `| telegram: ${order.metadata.telegram}` : ''}`;
    //     } else {
    //       return `Карта: ${order.card} | Банк: ${order.bank} | Сумма: ${order.amount} руб. ${order?.metadata?.telegram ? `| telegram: ${order.metadata.telegram}` : ''}`;
    //     }
    //   });
    //   let resultString = formattedStrings.join('\n');

    //   for (const order of res) {
    //     await this.state.updateColor(order.id)
    //   }

    //   return resultString;
    // },
    
    // getStatistic: async () => {
    //   await Manager.getStatistic().then((res) => {
    //     this.setState({ stats: res.body });
    //   })
    //   .catch((error) => {
    //     sendNotification("warning", "Ошибка ⚠️", `Не удалось загрузить статистику. Обратитесь в службу поддержки.`);
    //   });
    // },

    // getArchiveOrders: async () => {
    //   let params = {};
    //   if (this.state.filter.date) {
    //     params.date = moment(this.state.filter.date).format('YYYY-MM-DD');
    //   }
    //   if (!isEmpty(this.state.filter.status)) params.status = this.state.filter.status;
    //   await Manager.getArchiveOrders(params).then((res) => {
    //     this.setState({ orders: res.body.orders });
    //   })
    //   .catch((error) => {
    //     sendNotification("warning", "Ошибка ⚠️", `Не удалось загрузить заявки. Обратитесь в службу поддержки.`);
    //   });
    // },

    // updateOrder: async () => {
    //   let params = { id: this.state.activeOrder };
    //   if (!isEmpty(this.state.status)) params.status = this.state.status;

    //   let skipcheck = await secureLocalStorage.getItem("skipcheck");
      
    //   if (skipcheck === true) {
    //     params.skipcheck = skipcheck;
    //   } else {
    //     if (!isEmpty(this.state.check)) params.check = this.state.check;
    //   }
    //   if (!isEmpty(this.state.comment)) params.comment = this.state.comment;
    //   if (!isEmpty(this.state.comment2)) params.comment2 = this.state.comment2;

    //   await Manager.updateOrder(params).then((res) => {
    //     sendNotification("success", "Успешно", "Заявка успешно получила новый статус.");
    //   }).catch((error) => {
    //     sendNotification("danger", "Ошибка ⚠️", error.response?.body?.message);
    //   });

    //   await this.state.clear();
    //   await this.state.getActiveOrders();
    // },

    // withdraw: async () => {
    //   let params = {};
    //   if (!isEmpty(this.state.withdraw_amount)) params.amount = this.state.withdraw_amount;
    //   if (!isEmpty(this.state.withdraw_type)) params.type = this.state.withdraw_type;
    //   if (!isEmpty(this.state.withdraw_card)) params.card = this.state.withdraw_card;
    //   if (!isEmpty(this.state.withdraw_bank)) params.comment = this.state.withdraw_bank;
    //   await Manager.withdrawRequest(params).then((res) => {
    //     sendNotification("success", "Успешно", "Заявка на вывод создана.");
    //   })
    //   .catch((error) => {
    //     sendNotification("warning", "Ошибка ⚠️", `${error.response?.body?.message}`);
    //   });
    // },

    // addCardToDebt: async () => {
    //   let params = {};
    //   if (!isEmpty(this.state.card_to_debt)) params.card = this.state.card_to_debt;
    //   await Manager.addCardToDebt(params).then((res) => {
    //     sendNotification("success", "Успешно", "Карта добавлена в черный список.");
    //     this.setState({ card_to_debt: null });
    //   })
    //   .catch((error) => {
    //     sendNotification("warning", "Ошибка ⚠️", `${error.response?.body?.message}`);
    //   });
    // },

    // getWithdraws: async () => {
    //   await Manager.getWithdraws().then((res) => {
    //     this.setState({ withdraw_orders: res.body });
    //   })
    //   .catch((error) => {
    //     sendNotification("warning", "Ошибка ⚠️", `${error.response?.body?.message}`);
    //   });
    // },

    // getUsersWithdraws: async () => {
    //   await Manager.getUsersWithdraws().then((res) => {
    //     this.setState({ users_withdraw_orders: res.body });
    //   })
    //   .catch((error) => {
    //     sendNotification("warning", "Ошибка ⚠️", `${error.response?.body?.message}`);
    //   });
    // },

    // getDebtList: async () => {
    //   await Manager.getDebtList().then((res) => {
    //     this.setState({ debt_list: res.body });
    //   })
    //   .catch((error) => {
    //     sendNotification("warning", "Ошибка ⚠️", `${error.response?.body?.message}`);
    //   });
    // },

    // updateWithdrawStatus: async () => {
    //   let params = {};
    //   if (!isEmpty(this.state.withdraw_id)) params.withdrawalId = this.state.withdraw_id;
    //   if (!isEmpty(this.state.withdraw_status)) params.status = this.state.withdraw_status;
    //   await Manager.updateWithdrawStatus(params).then((res) => {
    //     sendNotification("success", "Успешно", "Заявка получила новый статус. Обновите страницу");
    //   })
    //   .catch((error) => {
    //     sendNotification("warning", "Ошибка ⚠️", `${error.response?.body?.message}`);
    //   });
    // },

    // timerPlus: async (id) => {
    //   if (isEmpty(id)) {
    //     sendNotification("warning", "Ошибка ⚠️", `id не можут быть пустым`);
    //     return;
    //   }
    //   await Manager.timerPlus(id).then((res) => {
    //     let orders = this.state.orders;
    //     let index = orders.findIndex(order => order.id === res.body.id);
    //     orders[index].timerTime = res.body.timerTime;
    //     this.setState({ orders: [...orders] });
    //     sendNotification("success", "Таймер обновлен.", `суммарное время на обработку заявки было увеличено на 5 минут.`);
    //   })
    //   .catch((error) => {
    //     sendNotification("warning", "Ошибка ⚠️", `${error.response?.body?.message}`);
    //   });
    // },

    // overdueOrder: async (id) => {
    //   this.setState({ orders: [...this.state.orders.filter(item => item.id !== id)] });
    // },

    // getCurrency: async () => {
    //   await System.getCurrency().then((res) => {
    //     this.setState({ currency: evaluate(`${res.body.price} - (${res.body.price} * 0.01)`)});
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     sendNotification("warning", "Ошибка ⚠️", `Не удалось загрузить курс. Обратитесь в службу поддержки.`);
    //   });
    // },

    // updateColor: async (id) => {
    //   let colors = await secureLocalStorage.getItem("colors");
    //   if (isEmpty(colors)) {
    //     colors = [];
    //   }

    //   colors.push(id);
    //   await secureLocalStorage.setItem("colors", colors);
    //   this.setState({ colors: colors });
    // },

    // clearColors: async () => {
    //   let colors = [];
    //   await secureLocalStorage.setItem("colors", colors);
    //   this.setState({ colors: colors });
    //   sendNotification("success", "Цвета очищены", `Все цвета удалены.`);
    // },

    // updateOrdersArray: async (id, value) => {
    //   if (value === true && !this.state.ordersArray.includes(id)) {
    //     await this.setState({ ordersArray: [...this.state.ordersArray, id]})
    //   } 

    //   if (value === false && this.state.ordersArray.includes(id)) {
    //     let arr = this.state.ordersArray.filter((item) => item !== id)
    //     await this.setState({ ordersArray: arr})
    //   } 
    // },

    // multiOrdersUpdate: async () => {
    //   let status = this.state.status;
    //   let orders = this.state.ordersArray;

    //   let skipcheck = await secureLocalStorage.getItem("skipcheck");

    //   let index = 0;

    //   for (const order of orders) {
    //     let params = {
    //       id: order,
    //       status: status
    //     }

    //     if (skipcheck === true) {
    //       params.skipcheck = skipcheck;
    //     } else {
    //       params.check = [this.state.checksArray[index]];
    //     }

    //     await Manager.updateOrder(params).then((res) => {
    //       sendNotification("success", "Успешно", `Заявка ${order} успешно.`);
    //       this.setState({ multiDoned: [...this.state.multiDoned, order]})
    //     }).catch((error) => {
    //       sendNotification("danger", `Заявка ${order} ⚠️`, error.response?.body?.message);
    //     });
    //     index++;
    //   }
    // },


    // stat: null,
    // colors: secureLocalStorage.getItem("colors"),
    // range: '1000-500000',
    // ordersArray: [],
    // redirect: false,
    // redirectPath: "",
    // redirectMessage: "",
    // multiDoned: [],
    // orders: [],
    // stats: "",
    // currency: "",
    // filter: {},
    // orderArray: [],
    // debt_list: [],

    // clear: () => {
    //   return this.setState({
    //     stat: null,
    //     redirect: false,
    //     redirectPath: "",
    //     ordersArray: [],
    //     multiDoned: [],
    //     redirectMessage: "",
    //     orders: [],
    //     debt_list: [],
    //     activeOrder: null,
    //     status: null,
    //     comment: null,
    //     comment2: null,
    //     checkFile: null,
    //     // skipcheck: null,
    //     withdraw_orders: [],
    //     stats: "",
    //     currency: "",
    //     filter: {},
    //     orderArray: [],
    //     check: null,
    //     checksArray: []
    //   });
    // },
  };

  render() {
    return (
      <ManagerContext.Provider value={this.state}>
        {this.props.children}
      </ManagerContext.Provider>
    );
  }
}