import React from "react";
// import { Trader } from "../helpers/api";
// import { isEmpty, sortBy } from "lodash";
// import  secureLocalStorage  from  "react-secure-storage";

// import { sendNotification } from "../helpers/notification";
// import moment from "moment";

export const TraderContext = React.createContext({});

export class TraderProvider extends React.Component {
  state = {
    // updateState: async (key, val) => {
    //   await this.setState((state) => {
    //     state[key] = val;
    //     return state;
    //   })
    // },

    // getProfit: async () => {
    //   await Trader.getProfit()
    //     .then((res) => {
    //       this.setState({ stat: res.body.data });
    //     })
    //     .catch((error) => {
    //       sendNotification("warning", "Ошибка ⚠️", `Что то пошло не так, не удалось загрузить баланс пользователя`);
    //     });
    // },

    // assignOrders: async () => {
    //   let banks = ['sberbank', 'tinkoff', 'visamaster'];

    //   let filteredBanks = banks.filter(bank => secureLocalStorage.getItem(bank));

    //   filteredBanks = filteredBanks.map(bank => {
    //     if (bank === 'visamaster') {
    //       return 'visa/master';
    //     }
    //     return bank;
    //   });

    //   await Trader.assignOrders({ assignedFrom: 'cms', banks: filteredBanks })
    //   .then((res) => {
    //     this.setState({ orders: [...this.state.orders, ...res.body.orders] });
    //     sendNotification("success", "Заявки получено", "Обновляю список заявок");
    //     this.setState({
    //       redirectPath: `/trader/orders`,
    //       redirect: true,
    //     });
    //   })
    //   .catch((error) => {
    //     // console.log("🚀 ~ file: manager-context.js:47 ~ ManagerProvider ~ assignOrders: ~ error:", error)
    //     sendNotification("warning", "Ошибка ⚠️", error.response?.body?.message);
    //   });
    // },

    // getActiveOrders: async () => {
    //   await Trader.getActiveOrders().then((res) => {
    //     this.setState({ orders: res.body.orders });
    //   })
    //   .catch((error) => {
    //     sendNotification("warning", "Ошибка ⚠️", `Не удалось загрузить заявки. Обратитесь в службу поддержки.`);
    //   });
    // },

    // getArchiveOrders: async () => {
    //   let params = {};
    //   if (this.state.filter.date) {
    //     params.date = moment(this.state.filter.date).format('YYYY-MM-DD');
    //   }
    //   if (!isEmpty(this.state.filter.status)) params.status = this.state.filter.status;
    //   await Trader.getArchiveOrders(params).then((res) => {
    //     this.setState({ orders: res.body.orders });
    //   })
    //   .catch((error) => {
    //     sendNotification("warning", "Ошибка ⚠️", `Не удалось загрузить заявки. Обратитесь в службу поддержки.`);
    //   });
    // },

    // getExportList: async () => {
    //   let res = await Trader.getActiveOrders().then((res) => {
    //     return res.body.orders;
    //   })
    //   .catch((error) => {
    //     sendNotification("warning", "Ошибка ⚠️", `Не удалось загрузить заявки. Обратитесь в службу поддержки.`);
    //   });

    //   let preSorted = sortBy(res, ['bank']);

    //   let formattedStrings = preSorted.map( order => {
    //     return `Карта: ${order.card} | Банк: ${order.bank} | Сумма: ${order.amount} руб.`;
    //   });
    //   let resultString = formattedStrings.join('\n');

    //   for (const order of res) {
    //     await this.state.updateColor(order.id)
    //   }

    //   return resultString;
    // },

    // overdueOrder: async (id) => {
    //   this.setState({ orders: [...this.state.orders.filter(item => item.id !== id)] });
    // },

    // updateOrder: async () => {
    //   let params = { id: this.state.activeOrder };
      
    //   if (!isEmpty(this.state.status)) params.status = this.state.status;
    //   if (!isEmpty(this.state.check)) params.check = this.state.check;
    //   if (!isEmpty(this.state.comment)) params.comment = this.state.comment;
    //   if (!isEmpty(this.state.comment2)) params.comment2 = this.state.comment2;

    //   await Trader.updateOrder(params).then((res) => {
    //     sendNotification("success", "Успешно", "Заявка успешно получила новый статус.");
    //   }).catch((error) => {
    //     sendNotification("danger", "Ошибка ⚠️", error.response?.body?.message);
    //   });

    //   await this.state.clear();
    //   await this.state.getActiveOrders();
    // },

    // getCheck: async (id) => {
    //   await Trader.getCheck(id).then((res) => {
    //     const arrayBuffer = res.body;
    //     const blob = new Blob([arrayBuffer], { type: res.type });
    //     const imageUrl = URL.createObjectURL(blob);
    //     this.setState({ 'checkFile': imageUrl})
    //   })
    //   .catch((error) => {
    //     sendNotification("warning", "Ошибка ⚠️", error.response?.body?.message);
    //   });
    // },
    
    // getStatistic: async () => {
    //   await Trader.getStatistic().then((res) => {
    //     this.setState({ stats: res.body });
    //   })
    //   .catch((error) => {
    //     sendNotification("warning", "Ошибка ⚠️", `Не удалось загрузить статистику. Обратитесь в службу поддержки.`);
    //   });
    // },

    // getWithdraws: async () => {
    //   await Trader.getWithdraws().then((res) => {
    //     this.setState({ withdraw_orders: res.body });
    //   })
    //   .catch((error) => {
    //     sendNotification("warning", "Ошибка ⚠️", `${error.response?.body?.message}`);
    //   });
    // },

    // withdraw: async () => {
    //   let params = {
    //     type: 'wallet'
    //   };
    //   if (!isEmpty(this.state.withdraw_amount)) params.amount = this.state.withdraw_amount;
    //   if (!isEmpty(this.state.withdraw_wallet)) params.wallet = this.state.withdraw_wallet;
    //   if (!isEmpty(this.state.withdraw_comment)) params.comment = this.state.withdraw_comment;
    //   await Trader.withdrawRequest(params).then((res) => {
    //     sendNotification("success", "Успешно", "Заявка на вывод создана.");
    //   })
    //   .catch((error) => {
    //     sendNotification("warning", "Ошибка ⚠️", `${error.response?.body?.message}`);
    //   });
    // },

    // updateColor: async (id) => {
    //   let colors = await secureLocalStorage.getItem("colors");
    //   if (isEmpty(colors)) {
    //     colors = [];
    //   }

    //   colors.push(id);
    //   await secureLocalStorage.setItem("colors", colors);
    //   this.setState({ colors: colors });
    // },

    // clearColors: async () => {
    //   let colors = [];
    //   await secureLocalStorage.setItem("colors", colors);
    //   this.setState({ colors: colors });
    //   sendNotification("success", "Цвета очищены", `Все цвета удалены.`);
    // },

    // updateOrdersArray: async (id, value) => {
    //   if (value === true && !this.state.ordersArray.includes(id)) {
    //     await this.setState({ ordersArray: [...this.state.ordersArray, id]})
    //   } 

    //   if (value === false && this.state.ordersArray.includes(id)) {
    //     let arr = this.state.ordersArray.filter((item) => item !== id)
    //     await this.setState({ ordersArray: arr})
    //   } 
    // },

    // stat: null,
    // colors: secureLocalStorage.getItem("colors"),
    // ordersArray: [],
    // redirect: false,
    // redirectPath: "",
    // redirectMessage: "",
    // orders: [],
    // stats: "",
    // filter: {},
    // orderArray: [],

    // clear: () => {
    //   return this.setState({
    //     stat: null,
    //     redirect: false,
    //     redirectPath: "",
    //     ordersArray: [],
    //     redirectMessage: "",
    //     orders: [],
    //     activeOrder: null,
    //     status: null,
    //     comment: null,
    //     comment2: null,
    //     checkFile: null,
    //     withdraw_orders: [],
    //     stats: "",
    //     filter: {},
    //     orderArray: [],
    //     check: null
    //   });
    // },
  };

  render() {
    return (
      <TraderContext.Provider value={this.state}>
        {this.props.children}
      </TraderContext.Provider>
    );
  }
}